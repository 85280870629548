import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Load',
    component: () => import('@/views/autentication/Load'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/autentication/Login'),
  },
  {
    path: '/raffles',
    component: () => import('@/views/raffles/View'),
  },
  {
    path: '/logout',
    component: () => import('@/views/autentication/Logout'),
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
